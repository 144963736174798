import { OrderItemType } from '@enums/order-item-type.enum';
import { Addition } from '@interfaces/addition.interface';
import { Dish } from './dish.model';

/**
 * Represents an order.
 */
export class Order {
  id?: number;
  dish?: Dish;
  additions?: Addition[];
  quantity?: number;
  price?: string;
  additionsPrice?: string;
  packagePrice?: string;
  discount?: string;
  status?: number;
  paid?: number;
  table?: number;
  restaurant_id?: string;
  type?: OrderItemType;

  /**
   * Creates an instance of the Order class.
   * @param data - The data to initialize the order with.
   */
  constructor(data: Order) {
    Object.assign(this, data);
    if (data.dish) this.dish = new Dish(data.dish);
  }
}
