import { DishDelivery } from '@enums/dish-delivery.enum';
import { Addition } from '@interfaces/addition.interface';
import { AdditionsGroups } from '@interfaces/additions-groups.interface';
import { AlergenTag } from '@interfaces/alergen-tag.interface';
import { DishLabel } from '@interfaces/dish-label.interface';
import { Promotion } from '@interfaces/promotion.interface';
import { Attribute } from '@models/attribute.model';
import { FoodCategory } from './food-category.model';

/**
 * Represents a dish in the application.
 */
export class Dish {
  /**
   * The unique identifier of the dish.
   */
  id?: number;

  /**
   * The category of the dish.
   */
  category?: FoodCategory;

  /**
   * The description of the dish.
   */
  description?: string;

  /**
   * The ID of the food category that the dish belongs to.
   */
  food_category_id?: number;

  /**
   * The name of the dish.
   */
  name?: string;

  /**
   * The translated name of the dish.
   */
  name_translation?: string;

  /**
   * The photo of the dish.
   */
  photo_url?: string;

  /**
   * The position of the dish in the menu.
   */
  position?: number;

  /**
   * The price of the dish.
   */
  price?: string;

  /**
   * The estimated waiting time for the dish.
   */
  timeWait?: number;

  /**
   * The additional price for the dish.
   */
  additionsPrice?: string;

  /**
   * Represents the groups of additions for a dish.
   */
  additions_groups?: AdditionsGroups[];

  /**
   * Indicates whether the dish is on promotion.
   */
  promotion?: Promotion | null;

  /**
   * Represents the labels associated with a dish.
   */
  labels?: DishLabel[];

  /**
   * Represents the tags associated with a dish.
   * @type {AlergenTag[]}
   */
  tags?: AlergenTag[];

  /**
   * Represents the attributes of a dish.
   */
  attributes?: Attribute[];

  /**
   * The additional items that can be added to the dish.
   */
  additions?: Addition[];

  /**
   * Represents the delivery information for a dish.
   */
  delivery?: DishDelivery;

  constructor(data: Dish) {
    Object.assign(this, data);
  }
}
