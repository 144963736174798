/**
 * Constructs the restaurant URL by combining the provided URL and restaurant name.
 * @param url - The URL to be appended to the restaurant URL.
 * @param restaurant - The name of the restaurant.
 * @returns The constructed restaurant URL.
 */
import { environment } from '@env';

export function GetRestaurantUrl(url: string, restaurant: string): string {
  return `${environment.https}${restaurant}.${environment.apiWithoutHttps}${url}`;
}
